<template>
  <div class="content-wrapper">
    <div class="employee main-content">
      <div class="page-header">
        <h1>Other Income / Outcome</h1>
      </div>
      <InOutItemForm :viewMode="true" :eventName="'updateInOutItem'" @updateInOutItem="updateInOutItem" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import InOutItemForm from '@/components/financial/InOutItemForm.vue';
import Common from '@/lib/common';
import Hr from '@/lib/hr';
import Financial from '@/lib/financial';

export default {
  name: 'InOutItem',
  components: {
    InOutItemForm,
  },
  mounted(){
    this.loadInOutItem();
  },
  methods:{
    async loadInOutItem(){
      try{
        const loginInfo = Common.getLoginInfo();
        const inOutItemId = this.$route.params.id;
        const inOutItem = await Financial.loadInOutItem(this.apiUrl, inOutItemId, loginInfo);
        this.setCurrentInOutItem(inOutItem);
      }catch(err){
        console.log(err);
      }
    },
    async updateInOutItem(form){
      try{
        const loginInfo = Common.getLoginInfo();
        const inOutItemId = this.$route.params.id;
        const inOutItemInfo = Object.assign({}, form);

        const employee = await this.getEmployeeByName(form.employeeId);
        const employeeId = employee.id;
        inOutItemInfo.employeeId = employeeId;

        const inOutItem = await Financial.updateInOutItem(this.apiUrl, inOutItemId, inOutItemInfo, loginInfo);
      }catch(err){
        console.log(err);
      }
    },
    async getEmployeeByName(memberName){
      const loginInfo = Common.getLoginInfo();
      const searchFields = {
        'employee_name': memberName,
      };
      const employees = await Hr.searchEmployee(this.apiUrl, searchFields, loginInfo);
      const employee = employees[0];
      return employee;
    },
    ...mapActions(['setCurrentInOutItem']),
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.page-header{
  h1{
    margin-bottom: 50px;
  }
}
</style>
